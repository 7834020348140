const getCookie = (key) => {
  const decodedCookie = window.document.cookie;
  const cookiesArray = decodedCookie.split(';');
  const cookie = cookiesArray.find(cookie => cookie.trim().indexOf(key) === 0);

  if (cookie) {
    return cookie.split(`${key}=`).pop();
  }

  return null;
};

const getJWT = () => getCookie('se_jwt');

const getHeaders = () => {
  const jwt = getJWT();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: jwt,
    'Up-Authorization': jwt,
  };

  return headers;
};

export const getCustomerAttributes = async () => {
  const { profileApiUrl } = window.SE_CLIENT_CONFIG;

  return fetch(`${profileApiUrl}?keys=owner_fullname`, {
    headers: getHeaders(),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.status);
      }

      return response.json();
    })
    .then((json) => {
      const { attributes } = json;

      return attributes;
    });
};

export const getCustomerInitials = (fullName) => {
  if (!fullName) {
    return '';
  }

  const ownerFullNameSplit = fullName.split(/[\s_-]/);

  if (ownerFullNameSplit.length === 1) {
    return ownerFullNameSplit[0].charAt(0).toUpperCase();
  }

  return ownerFullNameSplit
    .slice(0, 1)
    .concat(ownerFullNameSplit.slice(-1))
    .map(name => name.charAt(0))
    .join('')
    .toUpperCase();
};
