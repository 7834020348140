import theme from '../../theme';


export const page = {
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: '0',
  width: '100vw',
};

export const card = {
  alignItems: 'center',
  background: '#FFFFFF',
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: '20px',
  width: '100vw',

  [theme.breakpoints.up('mobile')]: {
    borderRadius: '8px',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    minHeight: 'auto',
    maxWidth: '400px',
    padding: '32px',
  },
};

const styles = { page, card };

export default styles;
