import { deepmerge } from '@mui/utils';
import { createTheme } from '@uplight/up-design-components/theming';


const hostAppOverrides = {
  breakpoints: {
    values: {
      mobile: 375,
      tablet: 768,
      desktop: 1280,
    },
  },
};

const theme = createTheme(deepmerge(hostAppOverrides, window.SE_THEME));

export default theme;
