import React from 'react';
import { Button } from '@uplight/up-design-components/components/Button';
import { Box } from '@uplight/up-design-components/components/Box';
import { Typography } from '@uplight/up-design-components/components/Typography';
import { Stack } from '@uplight/up-design-components/components/Stack';
import { page, card } from './styles';


const LogoutCompletePage = () => {
  const {
    logoutCompleteConfig: {
      BACKGROUND_IMAGE,
      DESCRIPTION,
      LOGIN_BUTTON_TEXT,
      LOGIN_BUTTON_URL,
      LOGO,
      LOGO_ALT_TEXT,
      TITLE,
    },
  } = window.SE_CLIENT_CONFIG;

  return (
    <Box
      data-testid="logout-complete-page--container"
      sx={{
        ...page,
        backgroundImage: BACKGROUND_IMAGE ? `url(${BACKGROUND_IMAGE})` : 'none',
        backgroundSize: 'cover',
      }}
    >
      <Box sx={{ ...card }}>
        <Stack spacing={9} alignItems="center">
          <img src={LOGO} alt={LOGO_ALT_TEXT} />
          <Stack spacing={2} alignItems="center">
            <Typography component="h1">
              {TITLE}
            </Typography>
            <Typography variant="body2">
              {DESCRIPTION}
            </Typography>
          </Stack>
          <Button
            href={LOGIN_BUTTON_URL}
            variant="contained"
          >
            {LOGIN_BUTTON_TEXT}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default LogoutCompletePage;
