import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import NavMenu from './NavMenu';
import theme from '../../theme';


const container = document.getElementById('header__nav-menu');

if (container) {
  const { headerContentConfig } = window.SE_CLIENT_CONFIG;
  const root = createRoot(container);

  root.render(
    <ThemeProvider theme={theme}>
      <NavMenu headerContent={headerContentConfig} />
    </ThemeProvider>,
  );
}
