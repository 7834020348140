import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import LogoutCompletePage from './LogoutCompletePage';
import theme from '../../theme';


const container = document.getElementById('logout-complete');

if (container) {
  const root = createRoot(container);

  root.render(
    <ThemeProvider theme={theme}>
      <LogoutCompletePage />
    </ThemeProvider>,
  );
}
