/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { AvatarMenu } from '@uplight/up-design-components/components/AvatarMenu';
import { MenuItem } from '@uplight/up-design-components/components/MenuItem';
import { getCustomerAttributes, getCustomerInitials } from '../../helpers';
import theme from '../../theme';


const NavMenu = ({ headerContent }) => {
  const [initials, setInitials] = useState('');
  const getInitials = async () => {
    const attributes = await getCustomerAttributes();
    const customerInitials = getCustomerInitials(attributes.owner_fullname);

    setInitials(customerInitials);
  };

  useEffect(() => {
    getInitials();
  }, []);

  const { AVATAR_BACKGROUND_COLOR, NAV } = headerContent;

  const menuChildren = NAV.map((menuItem) => {
    const { OPEN_NEW_TAB, PATH, TEXT } = menuItem;

    return (
      <MenuItem
        component="a"
        href={PATH}
        target={OPEN_NEW_TAB ? '_blank' : '_self'}
      >
        {TEXT}
      </MenuItem>
    );
  });

  return (
    <AvatarMenu
      avatarChildren={initials}
      avatarProps={{
        color: AVATAR_BACKGROUND_COLOR || 'primary',
      }}
      menuChildren={menuChildren}
      menuProps={{
        slotProps: {
          paper: {
            sx: {
              color: theme.palette.primary.main,
            },
          },
        },
      }}
    />
  );
};

export default NavMenu;
